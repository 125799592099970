import {
  formatZip, 
  formatPhone
} from "./../../components/utils/format"
import {
  QuestionsInterface,
  Options,
  QuestionOptionsInterface,
  AccountType,
  US_STATES,
} from "../index"
import {
  required,
  mustBeValidName,
  mustBeZipCode,
  mustBePhone,
} from "../../utils/validators"

export const DAYS = [
  {
    value: "sunday",
    label: "Sunday",
  },
  {
    value: "monday",
    label: "Monday",
  },
  {
    value: "tuesday",
    label: "Tuesday",
  },
  {
    value: "wednesday",
    label: "Wednesday",
  },
  {
    value: "thursday",
    label: "Thursday",
  },
  {
    value: "friday",
    label: "Friday",
  },
  {
    value: "saturday",
    label: "Saturday",
  },
]

export const SHIPPING_LOCATION: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "What is the shipping location name?",
    VALIDATION: [required, mustBeValidName('location')],

    OPTIONS: [
      {
        label: "Shipping Location Name",
        name: "location",
        id: "location",
        dataTestId: "location",
        type: "text",
        componentType: "Input",
      },
    ],
  },
]

export const BUSINESS_NETWORK: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "Corporation or business network you’re affiliated with",
    QUESTION_TEXT_HELPER: " (If Applicable)",
    VALIDATION: [mustBeValidName('corporateName')],

    OPTIONS: [
      {
        label: "Corporate Group Name",
        name: "corporateName",
        id: "corporateName",
        dataTestId: "corporate_name",
        type: "text",
        componentType: "Input",
      },
    ],
  },
]

export const SHIPPING_ADDRESS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "What is the shipping address?",

    OPTIONS: [
      {
        label: "Street Address",
        name: "street",
        id: "street",
        dataTestId: "street",
        type: "text",
        componentType: "Input",
        VALIDATION: [required],
      },
      {
        label: "Apartment, Suite, Etc.",
        name: "apartment",
        id: "apartment",
        dataTestId: "apartment",
        type: "text",
        componentType: "Input",
      },
      {
        label: "City",
        name: "city",
        id: "city",
        dataTestId: "city",
        type: "text",
        componentType: "Input",
        VALIDATION: [required],
      },
    ],
  },
]

export const BILLING_STATE: QuestionOptionsInterface = {
  componentType: "SelectDropDown",

  label: "State",

  id: "state",

  name: "state",

  type: "select",

  options: US_STATES,

  VALIDATION: [required],
}

export const SHIPPING_ZIP_CODE: QuestionOptionsInterface = {
  label: "ZIP Code",
  name: "zip",
  id: "zip",
  dataTestId: "zip",
  type: "text",
  componentType: "Input",
  helperText: "e.g. 21354",
  VALIDATION: [required, mustBeZipCode],
  format: formatZip,
}

export const PHONE_NUMBER_QUESTION = {
  question: "What is the phone number for this location?",
  option: {
    label: "Shipping Location Phone",
    name: "phone",
    id: "phone",
    dataTestId: "phone",
    type: "text",
    componentType: "Input",
    VALIDATION: [required, mustBePhone],
    format: formatPhone,
  },
}

export const SALES_TAX_CHARGE_QUESTION: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT:
      "Should Hill’s charge sales tax on purchases for this location?",
    VALIDATION: [required],

    OPTIONS: [
      {
        label: "Yes",
        name: "salesTax",
        value: Options.YES,
        id: "sales-tax-yes",
        dataTestId: "sales_tax",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        name: "salesTax",
        value: Options.NO,
        id: "sales-tax-no",
        dataTestId: "sales_tax",
        type: "radio",
        componentType: "Radio",
        helperText: "(To avoid taxation from Hill's, please attach your completed and signed state resale exemption certificate OR email to hillstax@hillspet.com within 5 business days after submitting your new account application)",
        helperClass: "font-bold text-sm",
      },
    ],
  },
]

export const EXEMPTION_CERTIFICATE = {
  label:
    "Please attach your completed and signed state resale or exemption certificate.",
  option: {
    label: "(Only PDF files with max size of 1mb)",
    name: "exemptionCertificate",
    id: "exemptionCertificate",
    type: "file",
    componentType: "FileUpload",
    // VALIDATION: [listNotEmpty('exemptionCertificate')],
  },
}

export const OPERATIONAL_HOURS_QUESTION = {
  label: "What are the days and hours of operation?",
  helperText: "",
}

export const OPERATIONAL_HOURS_INITIAL_VALUE = {
  sunday: [{duration: ""}],
  monday: [{duration: ""}],
  tuesday: [{duration: ""}],
  wednesday: [{duration: ""}],
  thursday: [{duration: ""}],
  friday: [{duration: ""}],
  saturday: [{duration: ""}],
}

export const OUTSIDE_HOURS_QUESTION: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT:
      "Does this location accept deliveries outside of the operating hours?",
    VALIDATION: [required],

    OPTIONS: [
      {
        label: "Yes",
        name: "outsideHours",
        value: Options.YES,
        id: "outside-hours-yes",
        dataTestId: "outside_hours",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        name: "outsideHours",
        value: Options.NO,
        id: "outside-hours-no",
        dataTestId: "outside_hours",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
]

export const OUTSIDE_OPERATIONAL_HOURS_QUESTION = {
  label: "What are the daily delivery windows for this location?",
  helperText: " (Earliest and latest delivery times)",
  fieldName: "outside",
  closedMessage: "Not Accepting Deliveries",
  placeholder: "Delivery Hours",
}

export const OUTSIDE_OPERATIONAL_HOURS_INITIAL_VALUE = {
  sundayOutside: [{duration: ""}],
  mondayOutside: [{duration: ""}],
  tuesdayOutside: [{duration: ""}],
  wednesdayOutside: [{duration: ""}],
  thursdayOutside: [{duration: ""}],
  fridayOutside: [{duration: ""}],
  saturdayOutside: [{duration: ""}],
}

export enum LocationEntryType {
  FRONT_DOOR = "FRONT_DOOR",
  BACK_DOOR = "BACK_DOOR",
  SIDE_DOOR = "SIDE_DOOR",
  RECEIVING_DOCK = "RECEIVING_DOCK",
}

export const LOCATION_ENTRY_MAP = {
  FRONT_DOOR: "Front Door",
  BACK_DOOR: "Back Door",
  SIDE_DOOR: "Side Door",
  RECEIVING_DOCK: "Receiving Dock",
}

export const DELIVERY_LOCATION_ENTRY_QUESTION = {
  question: "Where should we enter for delivery at this location?",
  option: {
    label: "Deliver To:",
    name: "entry",
    id: "entry",
    dataTestId: "entry",
    type: "select",
    componentType: "SelectDropDown",
    options: [
      {
        label: "Front Door",
        value: "FRONT_DOOR",
      },
      {
        label: "Back Door",
        value: "BACK_DOOR",
      },
      {
        label: " Side Door",
        value: "SIDE_DOOR",
      },
      {
        label: "Receiving Dock ",
        value: "RECEIVING_DOCK",
      },
    ],
    VALIDATION: [required],
  },
}

export const DELIVERY_APPOINTMENT_QUESTION: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "Is a delivery appointment required?",
    VALIDATION: [required],

    OPTIONS: [
      {
        label: "Yes",
        name: "appointment",
        value: Options.YES,
        id: "appointment-yes",
        dataTestId: "appointment",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        name: "appointment",
        value: Options.NO,
        id: "appointment-no",
        dataTestId: "appointment",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
]

export const BASE_ENTRY_QUESTION: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT:
      "What credentialing is required for delivery personnel to enter the base?",
    VALIDATION: [required],

    OPTIONS: [
      {
        label: "Required Credentialing",
        name: "baseEntry",
        id: "baseEntry",
        dataTestId: "base_entry",
        type: "text",
        componentType: "Input",
      },
    ],
  },
]

export const DELIVERY_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "What building or location should we deliver to?",

    OPTIONS: [
      {
        label: "Building or Location",
        name: "building",
        id: "building",
        dataTestId: "building",
        type: "text",
        componentType: "Input",
      },
    ],
  },

  {
    QUESTION_TEXT: "Are there steps to go up or down in making deliveries?",
    VALIDATION: [required],

    OPTIONS: [
      {
        label: "Yes",
        name: "steps",
        value: Options.YES,
        id: "steps-yes",
        dataTestId: "steps",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        name: "steps",
        value: Options.NO,
        id: "steps-no",
        dataTestId: "steps",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
  {
    QUESTION_TEXT:
      "Does this location have the ability to receive product on pallets?",
    VALIDATION: [required],

    OPTIONS: [
      {
        label: "Yes",
        name: "pallets",
        value: Options.YES,
        id: "pallets-yes",
        dataTestId: "pallets",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        name: "pallets",
        value: Options.NO,
        id: "pallets-no",
        dataTestId: "pallets",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
  {
    QUESTION_TEXT: "Is there a ramp for hand truck or pallet jack use?",
    VALIDATION: [required],

    OPTIONS: [
      {
        label: "Yes",
        name: "ramp",
        value: Options.YES,
        id: "ramp-yes",
        dataTestId: "ramp",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        name: "ramp",
        value: Options.NO,
        id: "ramp-no",
        dataTestId: "ramp",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
  {
    QUESTION_TEXT: "What is the closest major intersection?",
    OPTIONS: [
      {
        label: "Closest Intersection",
        name: "intersection",
        id: "intersection",
        dataTestId: "intersection",
        type: "text",
        componentType: "Input",
      },
    ],
  },
  {
    QUESTION_TEXT: "Are there any specific delivery instructions?",
    OPTIONS: [
      {
        label: "Delivery Instructions",
        name: "instructions",
        id: "instructions",
        dataTestId: "instructions",
        type: "text",
        componentType: "TextArea",
        helperText: "Nearest cross streets, where to park, etc.",
      },
    ],
  },
]

export const IGNORE_SALES_TAX = [
  AccountType.VET_TECH.toString(),
  AccountType.MILITARY.toString(),
]
export const SHOW_APPOINTMENT = [AccountType.VET_TECH.toString()]
export const SHOW_BUILDING = [
  AccountType.MILITARY.toString(),
  AccountType.VET_TECH.toString(),
]

export const SHOW_BASE_ENTRY = [AccountType.MILITARY.toString()]

export const HEADER = "Shipping Information"
