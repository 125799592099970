import { trackFieldValidation } from "./trackEvents";

/* eslint-disable @typescript-eslint/no-explicit-any */
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
const PHONE_REGEX = /^\d{10}$|^\(\d{3}\) \d{3}-\d{4}$/;
const SOLDTO_SHIPTO_REGEX = /^\d{6,10}$/
const NAME_REGEX = /^[A-Za-z0-9@#+&][a-zA-Z0-9#&+@ ]*$/
const ROUTING_NUMBER_REGEX = /^\d{9}$/
const ACCOUNT_NUMBER_REGEX = /^\d{1,17}$/
const ZIPCODE_REGEX = /^\d{5}$/

export const required = (value: any) =>
  value && value.trim() ? undefined : "Required"

export const listNotEmpty = (fieldName: string) => (value: any) => {
  if (value && value.length > 0) {
    return undefined;
  }
  const message = "Required";
  trackFieldValidation(fieldName, message);
  return message;
}

export const mustBeNumber = (value: number) =>
  isNaN(value) ? "Must be a number" : undefined

export const minValue = (min: number) => (value: number) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`

export const minLength = (min: number) => (value: string) =>
  value.length < min ? `Min ${min} characters` : undefined

export const maxLength = (fieldName: string, max: number) => (value: string) =>{
  if(value.trim().length > max){
    const message= `Maximum ${max} characters`;
    trackFieldValidation(fieldName, message);
    return message;
  }
  return undefined;
}

export const validRoutingNumber = (value: string) =>{
  if(value.match(ROUTING_NUMBER_REGEX)){
    return undefined;
  }
  const message= `Must be 9 digits and numeric only.`;
  trackFieldValidation('routingNumber', message);
  return message;
}

export const validAccountNumber = (value: string) =>{
  if(value.match(ACCOUNT_NUMBER_REGEX)){
    return undefined;
  }
  const message= `Maximum 17 digits and numeric only.`;
  trackFieldValidation('accountNumber', message);
  return message;
}

export const mustBeEmail = (value: string) =>{
  if(value.match(EMAIL_REGEX)){
    return undefined;
  }
  const message= `Enter valid email address`;
  trackFieldValidation('email', message);
  return message;
}
  

export const mustBePhone = (value: any) =>{
  if(!value || value.match(PHONE_REGEX)){
    return undefined;
  }
  const message= `Must be 10 numeric characters`;
  trackFieldValidation('phoneNumber', message);
  return message;
}

export const mustBeSoldTo = (value: any) =>{
  if(!value || value.match(SOLDTO_SHIPTO_REGEX)){
    return undefined;
  }
  const message= `Enter valid sold-to account number`;
  trackFieldValidation('soldTo', message);
  return message;
}

export const mustBeShipTo = (value: any) =>{
  if(!value || value.match(SOLDTO_SHIPTO_REGEX)){
    return undefined;
  }
  const message= `Enter valid ship-to account number`;
  trackFieldValidation('accountNum', message);
  return message;
}
export const mustBeValidName = (fieldName: string) => (value: string) =>{
  if(!value || value.match(NAME_REGEX)){
    return undefined;
  }
  const message= `Cannot contain special characters except (#, &, +, @)`;
  trackFieldValidation(fieldName, message);
  return message;
}

export const mustBeZipCode = (value: string) =>{
  if(value.match(ZIPCODE_REGEX)){
    return undefined;
  }
  const message= `Enter valid zip code`;
  trackFieldValidation('zip', message);
  return message;
}

export const mustBeSSN = (value: any) => {
  value = value.split("-").join("")
  if (value.length === 9) {
    return undefined
  }
  const message= `Enter valid SSN`;
  trackFieldValidation('ssn', message);
  return message;
}

export const mustBeEIN = (value: any) => {
  value = value.split("-").join("")
  if (value.length === 9) {
    return undefined
  }
  const message= `Enter valid employer identification number`;
  trackFieldValidation('ein', message);
  return message;
}

export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    )